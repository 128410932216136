export const RONDA_NAMING = [
    'Final',
    'Semifinal',
    'Cuartos de final',
    'Octavos de final',
    'Dieciseisavos de final',
    'Tercera ronda',
    'Segunda ronda',
    'Primera Ronda',
    'Liguilla',
]
    